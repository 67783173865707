import React from "react";

import * as Styled from "./Icon.styled";

const icons = {
  Envelope,
  Facebook,
  Instagram,
  Phone,
  TikTok,
  Twitter,
};

export default function Icon({ icon }) {
  const Icon = icons[icon] || Envelope;

  return (
    <Styled.IconWrapper>
      <Icon />
    </Styled.IconWrapper>
  );
}

function Envelope() {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7727 2.97188C11.8641 2.89922 12 2.96719 12 3.08203V7.875C12 8.49609 11.4961 9 10.875 9H1.125C0.503906 9 0 8.49609 0 7.875V3.08438C0 2.96719 0.133594 2.90156 0.227344 2.97422C0.752344 3.38203 1.44844 3.9 3.83906 5.63672C4.33359 5.99766 5.16797 6.75703 6 6.75234C6.83672 6.75938 7.6875 5.98359 8.16328 5.63672C10.5539 3.9 11.2477 3.37969 11.7727 2.97188ZM6 6C6.54375 6.00938 7.32656 5.31563 7.72031 5.02969C10.8305 2.77266 11.0672 2.57578 11.7844 2.01328C11.9203 1.90781 12 1.74375 12 1.57031V1.125C12 0.503906 11.4961 0 10.875 0H1.125C0.503906 0 0 0.503906 0 1.125V1.57031C0 1.74375 0.0796875 1.90547 0.215625 2.01328C0.932813 2.57344 1.16953 2.77266 4.27969 5.02969C4.67344 5.31563 5.45625 6.00938 6 6Z"
        fill="white"
      />
    </svg>
  );
}

function Facebook() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7143 0H1.28571C0.944722 0 0.617695 0.135459 0.376577 0.376577C0.135459 0.617695 0 0.944722 0 1.28571L0 10.7143C0 11.0553 0.135459 11.3823 0.376577 11.6234C0.617695 11.8645 0.944722 12 1.28571 12H4.96205V7.92027H3.27455V6H4.96205V4.53643C4.96205 2.8717 5.95313 1.95214 7.47107 1.95214C8.19804 1.95214 8.95821 2.08179 8.95821 2.08179V3.71571H8.12063C7.29536 3.71571 7.03795 4.22786 7.03795 4.75313V6H8.88027L8.58562 7.92027H7.03795V12H10.7143C11.0553 12 11.3823 11.8645 11.6234 11.6234C11.8645 11.3823 12 11.0553 12 10.7143V1.28571C12 0.944722 11.8645 0.617695 11.6234 0.376577C11.3823 0.135459 11.0553 0 10.7143 0Z"
        fill="white"
      />
    </svg>
  );
}

function Instagram() {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00267 2.92335C4.29967 2.92335 2.92602 4.297 2.92602 6C2.92602 7.703 4.29967 9.07665 6.00267 9.07665C7.70566 9.07665 9.07931 7.703 9.07931 6C9.07931 4.297 7.70566 2.92335 6.00267 2.92335ZM6.00267 8.00022C4.90214 8.00022 4.00245 7.1032 4.00245 6C4.00245 4.8968 4.89947 3.99978 6.00267 3.99978C7.10587 3.99978 8.00289 4.8968 8.00289 6C8.00289 7.1032 7.10319 8.00022 6.00267 8.00022ZM9.92278 2.7975C9.92278 3.19647 9.60145 3.51512 9.20516 3.51512C8.80619 3.51512 8.48754 3.1938 8.48754 2.7975C8.48754 2.40121 8.80886 2.07988 9.20516 2.07988C9.60145 2.07988 9.92278 2.40121 9.92278 2.7975ZM11.9605 3.52583C11.915 2.56454 11.6954 1.71304 10.9912 1.01149C10.2896 0.309941 9.43812 0.0903715 8.47683 0.0421734C7.4861 -0.0140578 4.51656 -0.0140578 3.52582 0.0421734C2.56722 0.0876939 1.71572 0.307263 1.01149 1.00881C0.307263 1.71036 0.0903713 2.56187 0.0421733 3.52315C-0.0140578 4.51389 -0.0140578 7.48343 0.0421733 8.47417C0.0876937 9.43546 0.307263 10.287 1.01149 10.9885C1.71572 11.6901 2.56454 11.9096 3.52582 11.9578C4.51656 12.0141 7.4861 12.0141 8.47683 11.9578C9.43812 11.9123 10.2896 11.6927 10.9912 10.9885C11.6927 10.287 11.9123 9.43546 11.9605 8.47417C12.0167 7.48343 12.0167 4.51657 11.9605 3.52583ZM10.6806 9.53721C10.4717 10.062 10.0674 10.4664 9.53987 10.6779C8.74996 10.9912 6.87559 10.9189 6.00267 10.9189C5.12975 10.9189 3.2527 10.9885 2.46546 10.6779C1.94064 10.469 1.53631 10.0647 1.32478 9.53721C1.01149 8.74729 1.08379 6.87292 1.08379 6C1.08379 5.12708 1.01417 3.25003 1.32478 2.46279C1.53364 1.93797 1.93796 1.53364 2.46546 1.3221C3.25538 1.00881 5.12975 1.08111 6.00267 1.08111C6.87559 1.08111 8.75263 1.01149 9.53987 1.3221C10.0647 1.53096 10.469 1.93529 10.6806 2.46279C10.9938 3.25271 10.9215 5.12708 10.9215 6C10.9215 6.87292 10.9938 8.74997 10.6806 9.53721Z"
        fill="white"
      />
    </svg>
  );
}

function Phone() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6578 8.47962L9.03274 7.35461C8.9206 7.30681 8.79597 7.29674 8.67761 7.32591C8.55925 7.35508 8.45357 7.4219 8.37648 7.51633L7.21396 8.93666C5.38949 8.07645 3.92121 6.60817 3.06099 4.7837L4.48133 3.62118C4.57594 3.54423 4.64291 3.43855 4.67209 3.32013C4.70127 3.20172 4.69108 3.07702 4.64305 2.96492L3.51803 0.339872C3.46532 0.219028 3.3721 0.120363 3.25444 0.0608898C3.13677 0.00141686 3.00205 -0.0151363 2.87349 0.0140846L0.435945 0.576595C0.311998 0.605217 0.201413 0.675005 0.122237 0.774571C0.0430614 0.874136 -2.85523e-05 0.997599 1.41945e-08 1.12481C1.41945e-08 7.13663 4.87274 12 10.8752 12C11.0024 12.0001 11.126 11.957 11.2256 11.8778C11.3252 11.7987 11.395 11.688 11.4236 11.5641L11.9862 9.12651C12.0152 8.99733 11.9983 8.86206 11.9383 8.74399C11.8784 8.62593 11.7792 8.53244 11.6578 8.47962Z"
        fill="white"
      />
    </svg>
  );
}

function TikTok() {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4996 4.92101C9.46751 4.92348 8.46072 4.60161 7.62146 4.00086V8.19067C7.62118 8.96667 7.38399 9.72408 6.94162 10.3616C6.49925 10.9992 5.87278 11.4865 5.14597 11.7584C4.41917 12.0303 3.62668 12.0738 2.87447 11.8832C2.12225 11.6925 1.44617 11.2768 0.93663 10.6915C0.427087 10.1062 0.108366 9.37936 0.0230853 8.60806C-0.0621952 7.83676 0.0900296 7.05781 0.459405 6.37536C0.828781 5.69291 1.3977 5.1395 2.09009 4.78911C2.78248 4.43873 3.56534 4.30808 4.33399 4.41463V6.52196C3.98226 6.41133 3.60456 6.41466 3.25483 6.53149C2.9051 6.64831 2.60123 6.87266 2.38661 7.17249C2.17199 7.47232 2.0576 7.83229 2.05977 8.20101C2.06195 8.56973 2.18057 8.92833 2.3987 9.22561C2.61684 9.52289 2.92333 9.74364 3.27441 9.85634C3.62549 9.96904 4.0032 9.96793 4.35361 9.85315C4.70402 9.73838 5.0092 9.51582 5.22557 9.21726C5.44195 8.91869 5.55845 8.55939 5.55844 8.19067V0H7.62146C7.62003 0.174218 7.63463 0.348198 7.66507 0.519741C7.73676 0.902685 7.88581 1.26698 8.10312 1.59034C8.32043 1.9137 8.60142 2.18933 8.9289 2.40037C9.39482 2.70844 9.94106 2.87264 10.4996 2.87252V4.92101Z"
        fill="white"
      />
    </svg>
  );
}

function Twitter() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7143 0H1.28571C0.575893 0 0 0.575893 0 1.28571V10.7143C0 11.4241 0.575893 12 1.28571 12H10.7143C11.4241 12 12 11.4241 12 10.7143V1.28571C12 0.575893 11.4241 0 10.7143 0ZM9.40446 4.25357C9.40982 4.32857 9.40982 4.40625 9.40982 4.48125C9.40982 6.80357 7.64196 9.47946 4.41161 9.47946C3.41518 9.47946 2.49107 9.19018 1.71429 8.69196C1.85625 8.70804 1.99286 8.71339 2.1375 8.71339C2.95982 8.71339 3.71518 8.43482 4.31786 7.96339C3.54643 7.94732 2.89821 7.44107 2.67589 6.74464C2.94643 6.78482 3.19018 6.78482 3.46875 6.7125C2.66518 6.54911 2.0625 5.84196 2.0625 4.9875V4.96607C2.29554 5.09732 2.56875 5.17768 2.85536 5.18839C2.6144 5.02812 2.41687 4.81068 2.28039 4.55549C2.14392 4.3003 2.07274 4.01529 2.07321 3.72589C2.07321 3.39911 2.15893 3.09911 2.31161 2.83929C3.17679 3.90536 4.47589 4.60179 5.93304 4.67679C5.68393 3.48482 6.57589 2.51786 7.64732 2.51786C8.15357 2.51786 8.60893 2.72946 8.93036 3.07232C9.32679 2.99732 9.70714 2.85 10.0446 2.64911C9.91339 3.05625 9.6375 3.39911 9.27321 3.61607C9.62679 3.57857 9.96964 3.47946 10.2857 3.34286C10.0473 3.69375 9.74732 4.00446 9.40446 4.25357Z"
        fill="white"
      />
    </svg>
  );
}
